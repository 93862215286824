import { Component, inject, signal } from '@angular/core';
import { RouterLink } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../core/services/auth.service';
import { SvgIconComponent } from '../../../../../../../shared/components/svg-icon/svg-icon.component';
import { NgClass, NgStyle } from '@angular/common';
import { SvgIconStaticComponent } from '../../../../../../../shared/components/svg-icon-static/svg-icon-static.component';
import { toSignal } from '@angular/core/rxjs-interop';
import { OrganizationSelectComponent } from '../organization-select/organization-select.component';
import { BadgeComponent } from '../badge/badge.component';
import { BadgePurpose } from '../badge/badge.models';

@Component({
  selector: 'sfa-header',
  standalone: true,
  imports: [
    RouterLink,
    SvgIconComponent,
    NgStyle,
    NgClass,
    SvgIconStaticComponent,
    OrganizationSelectComponent,
    BadgeComponent,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  protected readonly environment = environment;
  protected readonly BadgePurpose = BadgePurpose;

  private authService = inject(AuthService);

  user$ = toSignal(this.authService.user);
  userImageLoaded$ = signal(false);
}
