import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { inject } from '@angular/core';
import { Auth } from '@angular/fire/auth';

export function firebaseFunctionInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
  const auth = inject(Auth);

  if (
    req.url.startsWith(environment.domains.functionStripe) &&
    auth.currentUser
  ) {
    const authToken = (auth.currentUser as any).accessToken;

    const authReq = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${authToken}`),
    });

    return next(authReq);
  }

  // For other requests, do not modify them
  return next(req);
}
