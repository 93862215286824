import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { UserRole, UserStateModel } from './user.model';
import { SetUseRoleForActiveOrganization } from './user.action';

@State<UserStateModel>({
  name: 'userState',
  defaults: {
    roleInActiveOrganization: UserRole.USER,
  },
})
@Injectable()
export class UserState {
  @Selector()
  static roleForActiveOrganization(state: UserStateModel) {
    return state.roleInActiveOrganization;
  }

  @Action(SetUseRoleForActiveOrganization)
  SetUseRoleForActiveOrganization(
    ctx: StateContext<UserStateModel>,
    action: SetUseRoleForActiveOrganization,
  ) {
    ctx.patchState({
      roleInActiveOrganization: action.role,
    });
  }
}
