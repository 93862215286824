export interface User {
  id: string;
  email: string;
  role: UserRole;
}

export enum UserRole {
  USER = 'user',
  ADMIN = 'admin',
}

export interface UserStateModel {
  roleInActiveOrganization: UserRole;
}
