<div class="if-smaller-tablet">
  <div class="header-row">
    <sfu-svg-icon-static
      [svgClass]="'very-big'"
      routerLink="/"
    ></sfu-svg-icon-static>
    <sfa-organization-select class="sidenav-organization-select" />
  </div>
</div>

<div class="links-wrapper">
  <a
    class="link font-big"
    routerLink="/account/organizations"
    routerLinkActive="active bold"
  >
    <sfu-svg-icon
      src="groups"
      [badgePurposes]="[BadgePurpose.NO_ORGANIZATION]"
    />
    Vereine
  </a>
  <a class="link font-big" routerLink="/members" routerLinkActive="active bold">
    <sfu-svg-icon src="group" />
    Mitglieder
  </a>
  <a class="link font-big" routerLink="/events" routerLinkActive="active bold">
    <sfu-svg-icon src="format_list_bulleted" />
    Aktivitäten
  </a>
</div>
