<sfa-header/>
<div class="sidenav-main-wrapper">
  @if (user$() && environment.app) {
    <sfa-sidenav
      [ngClass]="{ 'show-mobile-sidenav': showMobileMenu$() }"
    />
    <div class="if-smaller-tablet">
      <div
        class="floating-menu-wrapper"
        (click)="showMobileMenu$.set(!showMobileMenu$())"
        tabindex
      >
        <sfu-svg-icon
          class="floating-menu"
          [src]="showMobileMenu$() ? 'close' : 'menu'"
          svgClass="white"
        />
      </div>
    </div>
  }
  <div class="content">
    @if (environment.app) {
      <main>
        <!--   todo     @if (loading$()) {-->
        <!--          <sfa-loading></sfa-loading>-->
        <!--        } @else {-->
        <router-outlet></router-outlet>
        <!--        }-->
      </main>
    } @else {
      <main>
        <router-outlet></router-outlet>
      </main>
    }
  </div>
</div>
@defer {
  <sfa-footer></sfa-footer>
}
@if (environment.app) {
  <sfa-feedback />
}
