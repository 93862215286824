import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { AuthService } from '../../../core/services/auth.service';
import { MatFormField } from '@angular/material/form-field';
import { MatOption, MatSelect } from '@angular/material/select';
import { SvgIconComponent } from '../../../../../../../shared/components/svg-icon/svg-icon.component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { filter } from 'rxjs';
import { StripeProductChipComponent } from '../stripe-product-chip/stripe-product-chip.component';
import { Store } from '@ngxs/store';
import { AsyncPipe } from '@angular/common';
import { LoadingComponent } from '../loading/loading.component';
import { environment } from '../../../../environments/environment';
import { LoadingState } from '../../../core/store/loading/loading.state';
import { OrganizationState } from '../../../core/store/organization/organization.state';
import { Organization } from '../../../core/store/organization/organization.model';
import { SetActiveOrganization } from '../../../core/store/organization/organization.action';

@Component({
  selector: 'sfa-organization-select',
  standalone: true,
  imports: [
    MatFormField,
    MatSelect,
    MatOption,
    SvgIconComponent,
    ReactiveFormsModule,
    StripeProductChipComponent,
    AsyncPipe,
    LoadingComponent,
  ],
  templateUrl: './organization-select.component.html',
  styleUrl: './organization-select.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationSelectComponent {
  protected readonly environment = environment;
  private store = inject(Store);

  organizationsLoading$ = toSignal(
    this.store.select(LoadingState.organizationsLoading),
    { requireSync: true },
  );
  organizations$ = toSignal(
    this.store.select(OrganizationState.organizations),
    { requireSync: true },
  );
  user$ = toSignal(inject(AuthService).user);
  activeOrganization$ = toSignal(
    this.store.select(OrganizationState.activeOrganization),
    { requireSync: true },
  );

  selectedOrganization: FormControl<Organization> = new FormControl();

  constructor() {
    effect(() => {
      this.selectedOrganization.setValue(this.activeOrganization$());
    });
    effect(
      () => {
        (this.organizations$()?.length || 0) <= 1
          ? this.selectedOrganization.disable()
          : this.selectedOrganization.enable();
      },
      { allowSignalWrites: true },
    );

    this.selectedOrganization.valueChanges
      .pipe(filter((org) => !!org && this.activeOrganization$()?.id !== org.id))
      .pipe(takeUntilDestroyed())
      .subscribe((organization) => {
        this.store.dispatch(new SetActiveOrganization(organization.id));
      });
  }
}
