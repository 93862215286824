import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SvgIconStaticComponent } from '../../../../../../../shared/components/svg-icon-static/svg-icon-static.component';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { SvgIconComponent } from '../../../../../../../shared/components/svg-icon/svg-icon.component';
import { OrganizationSelectComponent } from '../organization-select/organization-select.component';
import { BadgePurpose } from '../badge/badge.models';

@Component({
  selector: 'sfa-sidenav',
  standalone: true,
  imports: [
    SvgIconStaticComponent,
    RouterLink,
    SvgIconComponent,
    RouterLinkActive,
    OrganizationSelectComponent,
  ],
  templateUrl: './sidenav.component.html',
  styleUrl: './sidenav.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavComponent {
  protected readonly BadgePurpose = BadgePurpose;
}
