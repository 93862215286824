import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { UserRole } from '../store/user/user.model';
import { OrganizationState } from '../store/organization/organization.state';
import { ToastrService } from 'ngx-toastr';

export const userRoleAdminGuard: CanActivateFn = (route, state) => {
  const toastr = inject(ToastrService);

  const organizationId = route.params['id'];
  const userRole = inject(Store).selectSnapshot(
    OrganizationState.userRoleForOrganizationById(organizationId),
  );
  const userIsAdmin = userRole === UserRole.ADMIN;
  !userIsAdmin && toastr.info('Du bist nicht Admin dieses Vereins');
  return userIsAdmin;
};
